
export default {
  props: {
    rating: {
      required: true,
      type: Object
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
