
import { mapGetters } from 'vuex'

import CheaperTogetherProductStore from '@/components/product/CheaperTogetherProductStore'

export default {
  components: {
    CheaperTogetherProductStore
  },

  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    cheaperTogetherProductStores: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    warranties: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    addons: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    title: {
      type: String,
      default () {
        return this.$t('product.cheaperTogether.title')
      },
      required: false
    }
  },

  data () {
    return {
      options: {
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          watchOverflow: true,
          clickable: true
        },
        spaceBetween: 0,
        // simulateTouch: false,
        firstSlideReached: true,
        lastSlideReached: false,
        // allowTouchMove: false,
        on: {
          slideChange: () => {
            this.edgeReached()
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      recent: 'recent/recent'
    }),

    visibleSlidesPerView () {
      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        return 2
      } else if (['tablet'].includes(this.$mq)) {
        return 2
      } else if (['desktop'].includes(this.$mq)) {
        return 2
      } else if (['widescreen'].includes(this.$mq)) {
        return 2
      } else {
        return 2
      }
    },

    hideButtons () {
      if (['mobileSmall', 'mobile'].includes(this.$mq) && this.cheaperTogetherProductStores.length <= 2) {
        return true
      } else if (['tablet'].includes(this.$mq) && this.cheaperTogetherProductStores.length <= 2) {
        return true
      } else if (['desktop'].includes(this.$mq) && this.cheaperTogetherProductStores.length <= 2) {
        return true
      } else if (['widescreen'].includes(this.$mq) && this.cheaperTogetherProductStores.length <= 2) {
        return true
      } else if (['fullhd'].includes(this.$mq) && this.cheaperTogetherProductStores.length <= 2) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    slide (index) {
      this.swiper.slideTo(index, 1000, false)

      this.edgeReached()
    },

    edgeReached () {
      this.options.firstSlideReached = this.swiper.snapIndex === 0

      this.options.lastSlideReached = this.swiper.slides.length - this.swiper.snapIndex - this.visibleSlidesPerView === 0
    }
  }
}
