
import { mapGetters } from 'vuex'
import AuthModal from '@/components/modals/AuthModal'

export default {
  components: {
    AuthModal
  },

  props: {
    model: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    modal: {
      active: false,
      type: 'login'
    }
  }),

  computed: {
    ...mapGetters({
      country: 'country'
    }),

    inWishlist () {
      return this.$auth.user.wishlist.items.find(item => item.wishlistable_id === this.model.id)
    }
  },

  methods: {
    toggleModal (active = !this.modal.active) {
      if (this.modal.active && this.$auth.loggedIn) {
        this.addToWishlist()
      }

      this.modal.active = active
    },

    addToWishlist (modelType = this.model.class, modelId = this.model.id) {
      if (!this.$auth.loggedIn || !this.$auth.user) {
        this.modal.active = true

        return
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/wishlists`, {
        user_id: this.$auth.user.id,
        model: modelType,
        id: modelId
      })
        .then((res) => {
          this.$auth.fetchUser().then(() => {
            this.$emit('wished', {
              wishlists: res.data
            })
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
