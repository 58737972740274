
export default {
  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  }
}
