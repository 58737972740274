
import { mapGetters } from 'vuex'
// import AuthModal from '@/components/modals/AuthModal'

export default {
  // components: {
  //   AuthModal
  // },

  props: {
    model: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    // modal: {
    //   active: false,
    //   type: 'login'
    // }
  }),

  computed: {
    ...mapGetters({
      compare: 'compare/compare',
      country: 'country'
    }),

    inCompare () {
      return this.compare.items.find(item => item.compareable_id === this.model.id)
    }
  },

  methods: {
    // toggleModal (active = !this.modal.active) {
    //   this.modal.active = active
    // },

    toggleCompare (item) {
      this.$store.dispatch('compare/toggleCompare', { item })

      this.$emit('compared', {
        compares: this.compare
      })

      // if (!this.$auth.loggedIn || !this.$auth.user) {
      //   return
      // }

      // this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/compares`, {
      //     compare_id: this.compare.id,
      //     user_id: this.$auth.loggedIn ? this.$auth.user.id : null,
      //     model: modelType,
      //     id: modelId
      //   })
      //   .then((res) => {
      //     this.$auth.fetchUser()

      //     this.$emit('compared', {
      //       compares: res.data
      //     })
      //   })
      //   .catch((error) => {
      //     console.log('Cannot add to compare')
      //   })
    }
  }
}
