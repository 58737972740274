

export default {
  props: {
    stockStatus: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  computed: {
    stockStatusTagIcon () {
      if (this.stockStatus.type === 'available') {
        return ['fas', 'circle-check']
      } else if (this.stockStatus.type === 'awaiting') {
        return ['fas', 'truck-arrow-right']
      } else if (this.stockStatus.type === 'unavailable') {
        return ['fas', 'circle-xmark']
      } else if (this.stockStatus.type === 'preorder') {
        return ['fas', 'truck-arrow-right']
      } else if (this.stockStatus.type === 'discontinued') {
        return ['fas', 'circle-xmark']
      } else {
        return ['fas', '']
      }
    }
  }
}
