
import { mapGetters } from 'vuex'
import AuthModal from '@/components/modals/AuthModal'

export default {
  components: {
    AuthModal
  },

  props: {
    model: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    modal: {
      active: false,
      type: 'login'
    }
  }),

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  methods: {
    toggleModal (active = !this.modal.active) {
      this.modal.active = active
    },

    vote (modelType, modelId, helpful) {
      if (!this.$auth.loggedIn || !this.$auth.user) {
        this.modal.active = true

        return
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/votes`, {
        user_id: this.$auth.user.id,
        model: modelType,
        id: modelId,
        helpful
      },
      { progress: false })
        .then((res) => {
          this.$emit('voted', {
            votes: res.data,
            model: {
              id: modelId
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
